import * as React from "react"

import Advices from "../../organisms/advices"
import ImgText from "../../organisms/imgText"
import Pricing from "../../organisms/pricing"

const PortailsContent: React.FC<{ data: any }> = ({ data }) => {
  const {
    portailsChoisir,
    portailsImgText2,
    portailsImgText3,
    portailsPricing,
  } = data
  return (
    <>
      <Pricing data={portailsPricing} />
      <ImgText cta className="flex-row-reverse" data={portailsImgText2} />
      <Advices data={portailsChoisir} />
      <ImgText data={portailsImgText3} />
    </>
  )
}

export default PortailsContent
