import * as React from "react"

import Advices2 from "../../organisms/advices2"
import ImgText from "../../organisms/imgText"
import ImgText2 from "../../organisms/imgText2"
import Pricing from "../../organisms/pricing"

const PeintureContent: React.FC<{ data: any }> = ({ data }) => {
  const {
    peinturePricing,
    peintureCmsContent,
    peintureChoisir,
    peintureImgText2,
  } = data
  return (
    <>
      <Pricing data={peinturePricing} />
      <ImgText2 data={peintureCmsContent} />
      <Advices2 data={peintureChoisir} />
      <ImgText cta data={peintureImgText2} />
    </>
  )
}

export default PeintureContent
