import * as React from "react"
import classnames from "classnames"
import ReactHtmlParser from "react-html-parser"
import { FiInfo } from "react-icons/fi"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import SectionTitle from "../molecules/sectionTitle"
import Paragraph from "../atoms/paragraph"
import transform from "../../utils/htmlParser"
import { PricingProps } from "../../interfaces/PricingProps"
import PricingTable from "../molecules/pricingTable"
import PricingCta from "../molecules/pricingCta"
import Icon from "../../images/price-icon.png"
import Title from "../atoms/title"

const Pricing: React.FC<PricingProps> = ({ noTitle, data, className }) => {
  const Img = data.image && getImage(data.image.localFile)
  if (data) {
    return (
      <div
        className={classnames(
          "container mt-m pt-l pb-m md:pt-xl md:pb-l",
          className
        )}
      >
        <div className="text-center">
          <img src={Icon} alt={""} className="mx-auto -mb-m w-20" />
          <SectionTitle {...data.sectionTitle} className="max-w-2xl mx-auto" />
        </div>
        {data.pricingTables.length > 0 && (
          <div
            className={classnames(
              "max-w-full overflow-auto flex flex-wrap mb-m"
            )}
          >
            {data.info && (
              <div className="w-full">
                <div className="flex justify-center max-w-5xl mx-auto">
                  <div>
                    <FiInfo className="mb-s text-primary inline-block mx-s text-lg" />
                  </div>
                  <div className="italic">
                    {ReactHtmlParser(data.info, {
                      decodeEntities: true,
                      transform,
                    })}
                  </div>
                </div>
              </div>
            )}
            {data.sideText && (
              <div className="flex-1">
                {ReactHtmlParser(data.sideText, {
                  decodeEntities: true,
                  transform,
                })}
              </div>
            )}
            {data.pricingTables.map((el, i) => {
              if (el) {
                return (
                  <div className="mx-s my-m flex-1" key={i}>
                    {el.title && (
                      <Title className="text-center mb-s" as="h3">
                        {el.title}
                      </Title>
                    )}
                    <PricingTable noTitle={noTitle} data={el.pricingTable} />
                  </div>
                )
              } else {
                return null
              }
            })}
            {data.cta?.thumbnail && (
              <div className="max-w-sm">
                <PricingCta data={data.cta} />
              </div>
            )}
          </div>
        )}
        {data.cta && data.image && Img && (
          <div className="grid lg:grid-cols-2 gap-m">
            <GatsbyImage
              className="mx-auto"
              image={Img}
              alt={data.image.altText}
            />
            <div>
              {data.paragraph && (
                <Paragraph className="md:-mb-m">
                  {ReactHtmlParser(data.paragraph, {
                    decodeEntities: true,
                    transform,
                  })}
                </Paragraph>
              )}
              <PricingCta
                className="mt-m lg:mt-0 lg:-ml-l relative"
                data={data.cta}
              />
            </div>
          </div>
        )}
        {data.cta && !data.image && !data.cta.thumbnail && (
          <PricingCta data={data.cta} />
        )}
      </div>
    )
  } else {
    return null
  }
}

export default Pricing
