import * as React from "react"
import classnames from "classnames"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Title from "../atoms/title"
import Paragraph from "../atoms/paragraph"
import Rating from "../molecules/rating"
import ClickToCall from "./clickToCall"
import { HeroProps } from "../../interfaces/HeroProps"

const HeroAlt: React.FC<HeroProps> = ({ data, className }) => {
  const { title, subtitle } = data
  const backgroundImage =
    data.backgroundImage && getImage(data.backgroundImage.localFile)
  if (data) {
    return (
      <div
        className={classnames(
          "relative flex items-center overflow-hidden",
          className
        )}
        style={{ minHeight: "calc(100vh - 75px)" }}
      >
        <div className="container py-l mt-l mb-l flex flex-wrap flex-col md:flex-row z-10">
          <div className="w-full lg:w-5/12 xl:w-1/2 flex flex-col justify-center md:pr-m lg:pr-l"></div>
          <div className="w-full lg:w-7/12 xl:w-1/2 mt-m md:mt-0 flex flex-col justify-center">
            <Rating rating={data.rating} />
            {title && <Title as="h1">{title}</Title>}
            {subtitle && (
              <Paragraph className="text-base py-s">{subtitle}</Paragraph>
            )}
            <ClickToCall className="mt-m" />
          </div>
        </div>
        <div className="absolute top-0 z-0 w-full h-full flex justify-center">
          <div
            className={classnames(
              "absolute top-0 w-full h-full bg-hero-gradient-responsive lg:bg-hero-gradient-alt z-20"
            )}
          ></div>
          {backgroundImage && (
            <GatsbyImage
              className="z-10 min-w-full lg:mr-2xl"
              image={backgroundImage}
              alt={title}
            />
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default HeroAlt
