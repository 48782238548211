import * as React from "react"
import classnames from "classnames"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import StepItemProps from "../../interfaces/StepItemProps"
import Paragraph from "../atoms/paragraph"

const Step: React.FC<{
  data: StepItemProps
  index?: number
  className?: string
}> = ({ data, className, index }) => {
  const IconImg = data.icon && getImage(data.icon.localFile)
  return (
    <div className={classnames("text-lg px-xs md:px-s text-center", className)}>
      <div className="relative inline-block">
        {IconImg && (
          <GatsbyImage
            className="absolute -left-xs -top-s"
            image={IconImg}
            alt={data.icon.altText || ""}
          />
        )}
        <Paragraph className="ml-m text-light-gray text-xxxxl font-black">
          0{index}
        </Paragraph>
      </div>
      <Paragraph>
        <span className="font-bold block">{data.title}</span> {data.paragraph}
      </Paragraph>
    </div>
  )
}

export default Step
