import * as React from "react"
import classnames from "classnames"
import ReactHtmlParser from "react-html-parser"
import { FiInfo } from "react-icons/fi"

import { AdvicesProps } from "../../interfaces/AdvicesProps"
import SectionTitle from "../molecules/sectionTitle"
import AdviceCta from "../molecules/adviceCta"
import AdviceHint from "../molecules/adviceHint"
import ProjectCard from "../molecules/projectCard"
import transform from "../../utils/htmlParser"

const Advices: React.FC<AdvicesProps> = ({ data, className }) => {
  if (data) {
    return (
      <div className="bg-bicolor">
        <div
          className={classnames(
            "container pt-l pb-m md:pt-xl md:pb-l",
            className
          )}
        >
          {data.sectionTitle && (
            <SectionTitle
              {...data.sectionTitle}
              className="text-center max-w-4xl mx-auto"
            />
          )}
          {data.info && (
            <div className="mb-l bg-white flex p-m max-w-5xl mx-auto">
              <div>
                <FiInfo className="text-primary inline-block mx-s text-lg" />
              </div>
              <div className="italic">
                {ReactHtmlParser(data.info, {
                  decodeEntities: true,
                  transform,
                })}
              </div>
            </div>
          )}
          <div className="flex justify-center flex-wrap lg:flex-nowrap">
            {data.advices.map((el, i) => (
              <ProjectCard
                className="h-fit flex-1 mx-s inline-table mb-s"
                data={el}
                key={i}
              />
            ))}
          </div>
          {(data.hint || data.cta) && (
            <div className="mt-l flex flex-col gap-y-l md:gap-y-0 md:flex-row justify-center items-center items-center">
              {data.hint && <AdviceHint data={data.hint} />}
              {data.cta && <AdviceCta data={data.cta} />}
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Advices
