import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { AdvicesCtaProps } from "../../interfaces/AdvicesCtaProps"
import Paragraph from "../atoms/paragraph"

const AdviceCta: React.FC<{ data: AdvicesCtaProps }> = ({ data }) => {
  return (
    <Link
      to={data.uri || "/contact"}
      className="ml-m font-semibold group flex-1 max-w-lg"
    >
      <StaticImage
        src={"../../images/icon-cta.png"}
        alt="icon"
        width={80}
        className="-ml-m -mt-m float-left h-auto group-hover:animate-ping	"
      />
      <div className="mt-s bg-extra-light-gray w-full p-m">
        <Paragraph>{data.paragraph}</Paragraph>
        <Paragraph className="text-secondary">{data.paragraphAlt}</Paragraph>
      </div>
    </Link>
  )
}

export default AdviceCta
