import * as React from "react"
import classnames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import SectionTitle from "../molecules/sectionTitle"
import Step from "../molecules/step"
import StepsCta from "../molecules/stepsCta"
import StepItemProps from "../../interfaces/StepItemProps"
import { AdvicesCtaProps } from "../../interfaces/AdvicesCtaProps"
import Icon from "../../images/smiley.png"
import BgImg from "../../images/bg_marbre.jpeg"

const Steps: React.FC<{
  className?: string
  cta: AdvicesCtaProps
  icon?: any
}> = ({ cta, className, icon }) => {
  const data = useStaticQuery(graphql`
    query StepsQuery {
      wp {
        unscuzzyOptions {
          acfOptions {
            stepTitle
            stepSubtitle {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: AUTO
                    pngOptions: { compressionSpeed: 10, quality: 85 }
                    height: 85
                    layout: CONSTRAINED
                  )
                }
              }
            }
            steps {
              paragraph
              title
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: TRACED_SVG
                      formats: AUTO
                      pngOptions: { compressionSpeed: 10, quality: 85 }
                      width: 85
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const Icon = icon && getImage(icon.localFile)
  if (data) {
    const {
      steps,
      stepTitle,
      stepSubtitle,
    } = data.wp.unscuzzyOptions.acfOptions
    return (
      <div
        className={classnames(
          "pt-xl pb-m lg:mb-2xl bg-cover bg-bottom",
          className
        )}
        style={{ backgroundImage: `url(${BgImg})` }}
      >
        <div className="container">
          <SectionTitle
            title={stepTitle}
            subtitleImg={stepSubtitle}
            className="text-center"
          />
          <div className="relative mt-l grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-l lg:gap-m">
            {steps.map((step: StepItemProps, i: number) => (
              <Step data={step} index={i + 1} key={i} />
            ))}

            <img className="absolute right-0 bottom-0" src={Icon} alt={""} />
          </div>
          {cta && <StepsCta icon={Icon} data={cta} />}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Steps
