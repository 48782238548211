import * as React from "react"
import classnames from "classnames"
import ReactHtmlParser from "react-html-parser"
import { FiInfo } from "react-icons/fi"

import { AdvicesProps } from "../../interfaces/AdvicesProps"
import SectionTitle from "../molecules/sectionTitle"
import ProjectCard from "../molecules/projectCard"
import transform from "../../utils/htmlParser"
import Budget from "../molecules/budget"
import Paragraph from "../atoms/paragraph"
import { StaticImage } from "gatsby-plugin-image"

const Advices2: React.FC<AdvicesProps> = ({ data, className }) => {
  if (data) {
    return (
      <div className="bg-bicolor">
        <div className={classnames("container pt-l pb-m md:pb-l", className)}>
          {data.sectionTitle && (
            <SectionTitle
              {...data.sectionTitle}
              className="text-center max-w-4xl mx-auto mb-m"
            />
          )}
          {data.info && (
            <div className="mb-l bg-white flex p-m max-w-5xl mx-auto">
              <div>
                <FiInfo className="text-primary inline-block mx-s text-lg" />
              </div>
              <div className="italic">
                {ReactHtmlParser(data.info, {
                  decodeEntities: true,
                  transform,
                })}
              </div>
            </div>
          )}
          <div className="flex justify-center flex-wrap lg:flex-nowrap">
            {data.advices.map((el, i) => (
              <ProjectCard
                className="h-fit flex-1 mx-s inline-table"
                data={el}
                key={i}
              />
            ))}
            {data.budget && (
              <div className="mt-l lg:mt-0 h-fit flex-1 max-w-xs flex flex-col relative">
                <div className="z-10 absolute w-full flex justify-center -mt-m">
                  <div
                    style={{ height: 40 }}
                    className="top-0 bg-secondary max-w-max text-center italic font-bold font-title text-md py-xxs pr-m text-white flex items-center"
                  >
                    <StaticImage
                      height={50}
                      src={"../../images/icon_astuce.png"}
                      alt=""
                      className="transform scale-150 mr-s"
                    />
                    <Paragraph className="relative">
                      Astuces Petit Budget
                    </Paragraph>
                  </div>
                </div>
                {data.budget.map((el, i) => (
                  <Budget data={el} key={i} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Advices2
