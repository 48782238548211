import * as React from "react"
import { Link } from "gatsby"
import { FiArrowRight } from "react-icons/fi"
import classnames from "classnames"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"

import Paragraph from "../atoms/paragraph"
import { PricingCtaProps } from "../../interfaces/PricingCtaProps"

const PricingTable: React.FC<{ data: PricingCtaProps; className?: string }> = ({
  data,
  className,
}) => {
  if (data) {
    const ThumbnailImage = data.thumbnail && getImage(data.thumbnail.localFile)
    return (
      <div className={classnames("py-m flex justify-center", className)}>
        {/* <Link to={data.button || "/contact"}> */}
        <Link to={"/contact"}>
          <div className="bg-white max-w-2xl shadow-xl relative inline-block mx-auto font-semibold">
            {ThumbnailImage && data.thumbnail && (
              <>
                <GatsbyImage
                  className="w-full"
                  image={ThumbnailImage}
                  alt={data.thumbnail.altText}
                />
              </>
            )}
            <StaticImage
              src={"../../images/icon-thumb.png"}
              alt="icon"
              width={80}
              className="-ml-m -mt-m float-left h-auto group-hover:animate-ping"
            />
            {data.paragraph && (
              <div className="py-m px-l">
                <Paragraph>
                  <div dangerouslySetInnerHTML={{ __html: data.paragraph }} />
                </Paragraph>
              </div>
            )}
            {data.button && (
              <Link
                to="/contact"
                className="transition hover:bg-primary flex justify-center items-center bg-secondary text-white uppercase px-l py-m group"
              >
                {data.button}
                <FiArrowRight className="ml-xs text-xl group-hover:animate-ping" />
              </Link>
            )}
          </div>
        </Link>
      </div>
    )
  } else {
    return null
  }
}

export default PricingTable
