import * as React from "react"

import Advices2 from "../../organisms/advices2"
import Pricing from "../../organisms/pricing"

const ParquetContent: React.FC<{ data: any }> = ({ data }) => {
  const { parquetChoisir, parquetChoisir2, parquetPricing } = data
  return (
    <>
      <Pricing data={parquetPricing} />
      <Advices2 data={parquetChoisir} />
      <Advices2 data={parquetChoisir2} />
    </>
  )
}

export default ParquetContent
