import * as React from "react"
import classnames from "classnames"
import Slider from "react-slick"
import { FiArrowRight } from "react-icons/fi"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import SectionTitle from "../molecules/sectionTitle"
import Paragraph from "../atoms/paragraph"
import { CarouselWithNavProps } from "../../interfaces/CarouselWithNavProps"

const CarouselWithNav: React.FC<CarouselWithNavProps> = ({
  data,
  className,
}) => {
  function ArrowLeft(props: any) {
    const { className, onClick } = props
    return (
      <FiArrowRight
        className={classnames("alt-2", className)}
        onClick={onClick}
      />
    )
  }

  const [state, setState] = React.useState({ nav1: undefined, nav2: undefined })
  const slider1 = React.useRef()
  const slider2 = React.useRef()

  React.useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])
  const { nav1, nav2 } = state

  if (data.slides?.length > 0) {
    return (
      <div style={{ maxWidth: "100vw", overflow: "hidden" }}>
        <div
          className={classnames(
            "container pt-l pb-m md:pt-xl md:pb-l",
            className
          )}
        >
          <div className="flex flex-wrap lg:flex-nowrap items-center">
            <div
              className="w-full lg:w-6/12 xl:w-5/12 mr-m"
              style={{ maxHeight: "510px" }}
            >
              {data.sectionTitle && <SectionTitle {...data.sectionTitle} />}
              <Slider
                className="sliderwithtitle"
                asNavFor={nav1}
                ref={slider => (slider2.current = slider)}
                slidesToShow={data.slides.length}
                swipeToSlide={true}
                focusOnSelect={true}
                vertical={true}
              >
                {data.slides.map((el, i) => (
                  <div key={i} className="cursor-pointer">
                    <Paragraph className="mb-xs hover:underline">
                      <span className="inline-block text-secondary font-semibold mr-s">
                        {i + 1}.
                      </span>
                      {el.title}
                    </Paragraph>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="w-full -mt-xl md:-mt-l lg:mt-0 lg:w-6/12 xl:w-7/12 lg:ml-s">
              <link
                rel="stylesheet"
                type="text/css"
                charSet="UTF-8"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
              />
              <div>
                <Slider
                  asNavFor={nav2}
                  ref={slider => (slider1.current = slider)}
                  nextArrow={<ArrowLeft />}
                >
                  {data.slides.map((el, i) => {
                    const SideImage = getImage(el.image.localFile)
                    return SideImage ? (
                      <GatsbyImage
                        image={SideImage}
                        alt={el.image.altText}
                        key={i}
                      />
                    ) : null
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default CarouselWithNav
