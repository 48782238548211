import * as React from "react"

import Paragraph from "../atoms/paragraph"
import Title from "../atoms/title"
import { AdvicesHintProps } from "../../interfaces/AdvicesHintProps"
import { StaticImage } from "gatsby-plugin-image"

const AdviceHint: React.FC<{ data: AdvicesHintProps }> = ({ data }) => {
  return (
    <div className="mr-m italic flex flex-wrap max-w-lg">
      <div>
        <StaticImage
          src={"../../images/icon_astuce.png"}
          alt="icon"
          width={80}
          className="-mt-m"
        />
      </div>
      <div className="flex-1 mt-s">
        {data.title && <Title as="h5">{data.title}</Title>}
        <Paragraph>{data.paragraph}</Paragraph>
      </div>
    </div>
  )
}

export default AdviceHint
