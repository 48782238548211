import * as React from "react"
import classnames from "classnames"
import ReactHtmlParser from "react-html-parser"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import SectionTitle from "../molecules/sectionTitle"
import { ImgTextProps } from "../../interfaces/ImgTextProps"
import transform from "../../utils/htmlParser"
import Button from "../atoms/button"
import { useAppDispatch } from "../../app/hooks"
import { toggle } from "../../features/modal/modal-slice"

const ImgText: React.FC<ImgTextProps> = ({ data, className, cta }) => {
  const dispatch = useAppDispatch()
  const SideImage = data.background && getImage(data.background.localFile)
  if (data) {
    return (
      <div
        className={classnames(
          "w-full flex flex-wrap bg-extra-light-gray overflow-hidden",
          className
        )}
      >
        <div
          className={classnames(
            "relative h-20 sm:h-40 md:h-64 overflow-hidden lg:h-auto w-full lg:w-1/2 flex items-center"
          )}
        >
          {SideImage && (
            <GatsbyImage
              image={SideImage}
              alt={data.background.altText}
              className="w-full min-h-full"
              style={{ position: "absolute" }}
            />
          )}
        </div>
        <div className="lg:w-1/2 p-s lg:p-l my-m lg:max-w-2xl">
          <SectionTitle {...data.sectionTitle} />
          <div className="mt-s">
            {data.content &&
              ReactHtmlParser(data.content, {
                decodeEntities: true,
                transform,
              })}
            {cta && (
              <Button
                className="mt-m"
                alt
                large
                onclick={() => dispatch(toggle())}
              >
                DEVIS GRATUIT
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default ImgText
