import * as React from "react"
import classnames from "classnames"
import ReactHtmlParser from "react-html-parser"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Title from "../atoms/title"
import transform from "../../utils/htmlParser"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"

const Budget: React.FC<{
  data: {
    title?: string
    paragraph?: string
    content?: string
    thumbnail: { localFile: FileNode; altText: string }
  }
  className?: string
}> = ({ data, className }) => {
  const BgImg = data.thumbnail && getImage(data.thumbnail.localFile)
  return (
    <div
      className={classnames(
        "flex-1 shadow-lg bg-white inline-table",
        className
      )}
    >
      <div className="z-0 overflow-hidden h-32 sm:h-48 lg:h-56 2xl:h-64 relative">
        {BgImg && (
          <div className="absolute top-0 z-10 w-full h-full">
            <GatsbyImage
              style={{ minHeight: "100%" }}
              image={BgImg}
              alt={data.thumbnail.altText}
            />
          </div>
        )}
      </div>
      <div className="z-10 bg-white p-s border-dashed border-4 border-t-0 border-secondary">
        {data.title && (
          <Title className="mb-xs" as="h6">
            {data.title}
          </Title>
        )}
        {data.paragraph &&
          ReactHtmlParser(data.paragraph, {
            decodeEntities: true,
            transform,
          })}
      </div>
    </div>
  )
}

export default Budget
