import * as React from "react"
import ImgText from "../../organisms/imgText"

import ImgText2 from "../../organisms/imgText2"
import Pricing from "../../organisms/pricing"
import Pricing2 from "../../organisms/pricing2"

const ChauffageContent: React.FC<{ data: any }> = ({ data }) => {
  const {
    chauffageCmscontent,
    chauffageImgText,
    chauffagePricing,
    chauffagePricing2,
  } = data
  return (
    <>
      <ImgText2 data={chauffageCmscontent} />
      <Pricing2 data={chauffagePricing} />
      <ImgText cta className="flex flex-row-reverse" data={chauffageImgText} />
      <Pricing data={chauffagePricing2} />
    </>
  )
}

export default ChauffageContent
