import * as React from "react"
import { Link } from "gatsby"
import { FiArrowRight } from "react-icons/fi"
import { GatsbyImage } from "gatsby-plugin-image"

import Paragraph from "../atoms/paragraph"

const StepsCta: React.FC<{
  data: {
    uri: string
    paragraph: string
    paragraphAlt: string
  }
  icon?: any
}> = ({ data, icon }) => {
  if (data) {
    return (
      <div className="text-center transform my-m lg:my-0 lg:translate-y-1/2">
        <Link
          className="group text-left bg-extra-light-gray py-l px-m lg:pl-xl rounded-xl inline-flex flex-wrap relative max-w-2xl"
          to={data.uri}
        >
          <div className="shadow-xl w-32 h-32 flex items-center justify-center rounded-full bg-white mb-m lg:absolute lg:top-1/2 lg:left-0 transform lg:-translate-y-1/2 lg:-translate-x-1/2">
            {icon && (
              <div className="h-8 lg:w-14 h-8 lg:h-14">
                <GatsbyImage className="m-auto" image={icon} alt={""} />
              </div>
            )}
          </div>
          <div>
            <Paragraph className="text-lg font-black">
              {data.paragraph}
            </Paragraph>
            <Paragraph>{data.paragraphAlt}</Paragraph>
          </div>
          <FiArrowRight className="group-hover:animate-ping	text-xxxl lg:text-xxxxl text-secondary absolute bottom-0 right-0 transform translate-y-1/2" />
        </Link>
      </div>
    )
  } else {
    return null
  }
}

export default StepsCta
