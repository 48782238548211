import * as React from "react"

import Advices from "../../organisms/advices"
import Pricing2 from "../../organisms/pricing2"
import ImgText from "../../organisms/imgText"

const FenetresContent: React.FC<{ data: any }> = ({ data }) => {
  const {
    fenetresPricing,
    fenetresImgText2,
    fenetresChoisir2,
    fenetresChoisir3,
    fenetresChoisir,
  } = data
  return (
    <>
      <Advices data={fenetresChoisir} />
      <Advices data={fenetresChoisir2} />
      <Pricing2 noTitle data={fenetresPricing} />
      <Advices data={fenetresChoisir3} />
      <ImgText cta className="flex flex-row-reverse" data={fenetresImgText2} />
    </>
  )
}

export default FenetresContent
