import * as React from "react"

import Advices from "../../organisms/advices"
import ImgText2 from "../../organisms/imgText2"
import Pricing from "../../organisms/pricing"

const TerrasseContent: React.FC<{ data: any }> = ({ data }) => {
  const { terrassesChoisir, terrassesPricing, terrassesCmscontent } = data
  return (
    <>
      <Advices data={terrassesChoisir} />
      <Pricing data={terrassesPricing} />
      <ImgText2 data={terrassesCmscontent} />
    </>
  )
}

export default TerrasseContent
