import * as React from "react"
import classnames from "classnames"
import Slider from "react-slick"
import { FiArrowRight, FiArrowLeft } from "react-icons/fi"
import ReactHtmlParser from "react-html-parser"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import SectionTitle from "../molecules/sectionTitle"
import ProjectCard from "../molecules/projectCard"
import transform from "../../utils/htmlParser"
import { PortfolioProps } from "../../interfaces/PortfolioProps"

const PortfolioAlt: React.FC<PortfolioProps> = ({ data, className }) => {
  const icon = data.icon && getImage(data.icon.localFile)
  function ArrowLeft(props: any) {
    const { className, onClick } = props
    return (
      <FiArrowRight className={classnames("", className)} onClick={onClick} />
    )
  }
  function ArrowRight(props: any) {
    const { className, onClick } = props
    return (
      <FiArrowLeft className={classnames("", className)} onClick={onClick} />
    )
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <ArrowLeft />,
    prevArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  if (data.projects.length > 0) {
    return (
      <div
        className={classnames(
          "mb-l pl-s pr-s lg:pb-m lg:pl-xl xl:pl-2xl lg:pr-0 grid-cols-1 lg:grid-cols-2 grid items-center",
          className
        )}
      >
        <div className="z-10 sm:mr-l max-w-lg ml-auto">
          {icon && (
            <div className="-mt-m mb-s bg-white w-28 h-28 p-m rounded-full">
              <GatsbyImage
                className="m-auto"
                image={icon}
                alt={data.icon?.altText || ""}
              />
            </div>
          )}
          <SectionTitle {...data.sectionTitle} />
          <div className="mt-s">
            {data.content &&
              ReactHtmlParser(data.content, {
                decodeEntities: true,
                transform,
              })}
          </div>
        </div>
        {data.projects.length > 1 ? (
          <div className="max-w-full my-l">
            <link
              rel="stylesheet"
              type="text/css"
              charSet="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            <Slider {...settings}>
              {data.projects.map((el, i) => (
                <ProjectCard className="m-xs md:m-s" data={el} key={i} />
              ))}
            </Slider>
          </div>
        ) : (
          data.projects.map((el, i) => (
            <div className="max-w-xs lg:mt-l" key={i}>
              <ProjectCard className="m-xs md:m-s" data={el} />
            </div>
          ))
        )}
      </div>
    )
  } else {
    return null
  }
}

export default PortfolioAlt
