import * as React from "react"

import Advices from "../../organisms/advices"
import Pricing2 from "../../organisms/pricing2"

const CuisineContent: React.FC<{ data: any }> = ({ data }) => {
  const { cuisinePricing, cuisineChoisir } = data
  return (
    <>
      <Pricing2 noTitle data={cuisinePricing} />
      <Advices data={cuisineChoisir} />
    </>
  )
}

export default CuisineContent
