import * as React from "react"

import Advices from "../../organisms/advices"
import Pricing2 from "../../organisms/pricing2"

const SdbContent: React.FC<{ data: any }> = ({ data }) => {
  const { sdbChoisir, sdbPricing } = data
  return (
    <>
      <Pricing2 noTitle data={sdbPricing} />
      <Advices data={sdbChoisir} />
    </>
  )
}

export default SdbContent
