import * as React from "react"

const PricingTable: React.FC<{ noTitle?: boolean; data: any[] }> = ({
  noTitle,
  data,
}) => {
  if (!data) return null
  return (
    <table className="table-auto w-full max-w-4xl mx-auto">
      {!noTitle && (
        <thead>
          <tr>
            {data[0].title && <th>{data[0].title}</th>}
            {data[0].infos && <th>{data[0].infos}</th>}
            {data[0].price && <th className="sm:min-w-xxs">{data[0].price}</th>}
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((el, i) => {
          if (noTitle || (!noTitle && i !== 0)) {
            return (
              <tr key={i}>
                {el?.title && <td>{el?.title}</td>}
                {el.infos && <td className="font-normal">{el.infos}</td>}
                {el.price && <td className="text-primary">{el.price}</td>}
              </tr>
            )
          } else {
            return null
          }
        })}
      </tbody>
    </table>
  )
}

export default PricingTable
