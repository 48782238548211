import * as React from "react"
import classnames from "classnames"
import ReactHtmlParser from "react-html-parser"
import { FiInfo } from "react-icons/fi"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import SectionTitle from "../molecules/sectionTitle"
import transform from "../../utils/htmlParser"
import { PricingProps } from "../../interfaces/PricingProps"
import PricingTable from "../molecules/pricingTable"
import PricingCta from "../molecules/pricingCta"
import Title from "../atoms/title"
import Icon from "../../images/price-icon.png"

const Pricing2: React.FC<PricingProps> = ({ noTitle, data, className }) => {
  const Img = data.image && getImage(data.image.localFile)
  const Img2 = data.imageText && getImage(data.imageText.image.localFile)
  if (data) {
    return (
      <div
        className={classnames(
          "container pt-l pb-m md:pt-xl md:pb-l",
          className
        )}
      >
        <div className="text-center">
          <img src={Icon} alt={""} className="mx-auto -mb-m w-20" />
          <SectionTitle {...data.sectionTitle} className="max-w-2xl mx-auto" />
        </div>
        {data.info && (
          <div className="flex justify-center max-w-5xl mx-auto">
            <div>
              <FiInfo className="mb-s text-primary inline-block mx-s text-lg" />
            </div>
            <div className="italic">
              {ReactHtmlParser(data.info, {
                decodeEntities: true,
                transform,
              })}
            </div>
          </div>
        )}
        {data.pricingTables.length > 0 && (
          <div className={classnames("flex flex-wrap mb-m overflow-x-auto")}>
            {data.pricingTables.map((el, i) => {
              return (
                <div className="md:mx-s my-m flex-1" key={i}>
                  {el.title && (
                    <Title className="text-center mb-s" as="h3">
                      {el.title}
                    </Title>
                  )}
                  <PricingTable noTitle={noTitle} data={el.pricingTable} />
                </div>
              )
            })}
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-m">
          {Img && (
            <GatsbyImage
              className="w-full"
              image={Img}
              alt={data.image?.altText || ""}
            />
          )}
          <div className="mt-s">
            {data.paragraph &&
              ReactHtmlParser(data.paragraph, {
                decodeEntities: true,
                transform,
              })}
            <PricingCta
              className="-mt-s pt-s lg:mt-0 lg:-ml-l relative"
              data={data.cta}
            />
          </div>
        </div>
        {data.imageText && (
          <div className="flex flex-wrap lg:flex-nowrap items-center mt-l">
            <div className="w-full md:w-1/2 mr-m">
              {data.imageText.content &&
                ReactHtmlParser(data.imageText.content, {
                  decodeEntities: true,
                  transform,
                })}
            </div>
            <div className="w-full md:w-1/2">
              {Img2 && (
                <GatsbyImage
                  image={Img2}
                  alt={data.imageText.image.altText || ""}
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default Pricing2
