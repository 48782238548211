import * as React from "react"
import { PageProps, graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../layout"
import HeroAlt from "../organisms/heroAlt"
import PortfolioAlt from "../organisms/portfolioAlt"
import Testimonials from "../organisms/testimonials"
import Steps from "../organisms/steps"
import ImgText from "../organisms/imgText"
import CarouselWithNav from "../organisms/carouselWithNav"
import TerrasseContent from "./services/terrasses"
import PortailsContent from "./services/portails"
import SdbContent from "./services/sdb"
import ChauffageContent from "./services/chauffage"
import PeintureContent from "./services/peinture"
import ParquetContent from "./services/parquet"
import FenetresContent from "./services/fenetres"
import CuisineContent from "./services/cuisine"

type DataProps = {
  wpService: {
    seo: any
    template: {
      templateName: string
    }
    hero_section: any
    postTypeService: {
      global: any
      introduction: any
      imgText: any
      portfolio: any[]
      testimonials: any[]
      testimonialsTitle: string
      carouselWithNav: any
      callToAction: any
    }
    sectionTerrasses: any
    sectionSdb: any
    sectionPortails: any
    sectionPeinture: any
    sectionParquet: any
    sectionFenetres: any
    sectionChauffage: any
    sectionCuisines: any
  }
}

const servicePostType: React.FC<PageProps<DataProps>> = ({ data }) => {
  const {
    hero_section,
    postTypeService,
    sectionTerrasses,
    sectionSdb,
    sectionPortails,
    sectionPeinture,
    sectionCuisines,
    sectionChauffage,
    sectionFenetres,
    sectionParquet,
  } = data.wpService
  const {
    global,
    introduction,
    portfolio,
    imgText,
    carouselWithNav,
    testimonials,
    testimonialsTitle,
    callToAction,
  } = postTypeService
  const { templateName } = data.wpService.template
  return (
    <Layout>
      <Seo post={data.wpService} />
      <HeroAlt data={hero_section} />
      <PortfolioAlt
        data={{
          icon: global.icon,
          sectionTitle: {
            title: introduction.title,
            subtitleImg: introduction.subtitleimage,
          },
          content: introduction.content,
          projects: portfolio,
        }}
      />
      <ImgText data={imgText} />
      <div>
        {templateName === "Terrasses" ? (
          <TerrasseContent data={sectionTerrasses} />
        ) : templateName === "SDB" ? (
          <SdbContent data={sectionSdb} />
        ) : templateName === "Portails" ? (
          <PortailsContent data={sectionPortails} />
        ) : templateName === "Peinture" ? (
          <PeintureContent data={sectionPeinture} />
        ) : templateName === "Parquet" ? (
          <ParquetContent data={sectionParquet} />
        ) : templateName === "Fenetres" ? (
          <FenetresContent data={sectionFenetres} />
        ) : templateName === "Cuisine" ? (
          <CuisineContent data={sectionCuisines} />
        ) : templateName === "Chauffage" ? (
          <ChauffageContent data={sectionChauffage} />
        ) : null}
      </div>
      <CarouselWithNav data={carouselWithNav} />
      <Steps
        icon={global.icon}
        cta={{
          uri: "/contact",
          paragraph: callToAction.titre,
          paragraphAlt: callToAction.paragraph,
        }}
      />
      <Testimonials
        data={{
          sectionTitle: {
            title: testimonialsTitle || "Ils adorent Sky Rénov",
            subtitle: "(Et ils en parlent)",
          },
          testimonials: testimonials,
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query servicesPostTypesQuery($id: String) {
    wpService(id: { eq: $id }) {
      nodeType
      title
      uri
      template {
        templateName
      }
      ...seoServicesFields
      ...heroServicesFields
      ...servicePostFields
      ...sectionTerrassesFields
      ...sectionSdbFields
      ...sectionPortailsFields
      ...sectionPeintureFields
      ...sectionParquetFields
      ...sectionFenetresFields
      ...sectionCuisineFields
      ...sectionChauffageFields
    }
  }
`

export default servicePostType
