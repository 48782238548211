import * as React from "react"
import classnames from "classnames"
import ReactHtmlParser from "react-html-parser"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { FiInfo } from "react-icons/fi"

import AdviceHint from "../molecules/adviceHint"
import SectionTitle from "../molecules/sectionTitle"
import PricingCta from "../molecules/pricingCta"
import { ImgText2Props } from "../../interfaces/ImgText2Props"
import transform from "../../utils/htmlParser"

const ImgText2: React.FC<ImgText2Props> = ({ data, className }) => {
  if (data) {
    return (
      <div
        className={classnames(
          "w-full flex flex-wrap bg-extra-light-gray overflow-hidden",
          className
        )}
      >
        <div className="container pt-l pb-m md:pt-xl md:pb-l">
          <div className="text-center">
            <SectionTitle {...data.sectionTitle} />
          </div>
          <div>
            {data.info && (
              <div className="flex justify-center max-w-5xl mx-auto mb-m">
                <div>
                  <FiInfo className="mb-s text-primary inline-block mx-s text-lg" />
                </div>
                <div className="italic max-w-2xl">
                  {ReactHtmlParser(data.info, {
                    decodeEntities: true,
                    transform,
                  })}
                </div>
              </div>
            )}
            {data.textImage.map((el, i) => {
              const SideImage = getImage(el.image.localFile)
              return (
                <div
                  className={
                    i % 2 !== 0
                      ? "flex flex-wrap flex-row-reverse mb-m items-center"
                      : "flex flex-wrap mb-m items-center"
                  }
                >
                  <div className="p-m md:w-1/2">
                    {ReactHtmlParser(el.content, {
                      decodeEntities: true,
                      transform,
                    })}
                  </div>
                  {SideImage && (
                    <GatsbyImage
                      className="w-full md:w-1/2"
                      image={SideImage}
                      alt={el.image.altText}
                    />
                  )}
                </div>
              )
            })}
            {(data.budget || data.cta) && (
              <div className="flex flex-wrap justify-center">
                {data.budget && <AdviceHint data={data.budget} />}
                {data.cta && <PricingCta data={{ paragraph: data.cta }} />}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default ImgText2
